<template>
  <v-navigation-drawer
    v-model="getThemeMode.verticalSidebarDrawer"
    :color="getThemeMode.verticalSidebarDrawerColor"
    :width="98"
    :expand-on-hover.sync="getThemeMode.verticalSidebarMini"
    :mini-variant.sync="mini"
    :mini-variant="getThemeMode.verticalSidebarMini"
    :dark="getThemeMode.verticalSidebarDrawerColor !== 'white'"
    app
    :disable-resize-watcher="false"
    :mobile-breakpoint="960"
    height="100%"
    class="shadow-sm d-print-none"
    :floating="true"
    :right="$vuetify.rtl"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :style="{ opacity: opacity }" v-bind="props" class="test" />
    </template>
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="h-100 rtl-ps-none ps scroll"
      style="height: 100%"
    >
      <v-list class="py-5">
        <v-list-item class="px-0">
          <v-list-item
            class=""
          >
            <v-img
              width="50"
              src="@/assets/images/logos/LogoVumetricSmall.svg"
              alt="Vumetric logo"
            />
          </v-list-item>
        </v-list-item>
      </v-list>

      <v-list nav>
        <template v-for="(item, i) in computedItems">
          <div
            v-if="item.subheader"
            :key="item.subheader"
            :class="miniVariant && 'px-1'"
          >
            <v-subheader>
              {{ item.subheader }}
            </v-subheader>

            <v-divider />
          </div>

          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          />

          <base-item
            v-else-if="!item.subheader && !item.children"
            :key="`item-${i}`"
            :item="item"
          />
        </template>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { items } from '@/data/navigation'

export default {
  props: ['me'],
  data() {
    return {
      drawer: true,
      switch1: 'on',
      color: 'dark',
      colors: ['primary', 'secondary', 'success', 'error', 'teal'],
      right: true,
      permanent: true,
      miniVariant: true,
      mini: true,
      expandOnHover: false,
      background: false,
      items: items,
      opacity: 0.4,
      sidebarState: false
    }
  },
  computed: {
    ...mapGetters(['getThemeMode']),
    getMiniVariant() {
      return this.miniVariant
    },
    computedItems() {
      return this.items.map(this.mapItem)
    }
  },
  methods: {
    ...mapActions(['changeVerticalSidebarDrawer', 'changeVerticalSidebarMini', 'changeUserDrawer']),

    gotoWebsite() {
      window.location.href = 'https://www.vumetric.com'
    },
    toggleSidebar() {
      this.changeVerticalSidebarMini()
      this.expandOnHover = !this.expandOnHover
      // this.$emit('update:mini-variant')
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title
      }
    }
  }
}
</script>

<style lang="scss">
.pointer{
  cursor: pointer;
}
.ps-container {
  position: inherit !important;
}
.app-admin-wrap-layout-1.sidebar-mini {
  .v-toolbar {
    left: 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .v-main {
    padding: 75px 0 12px 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding-bottom: 0 !important;
  }
  &.sidebar-close {
    .v-toolbar {
      left: 0 !important;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .v-main {
      padding: 75px 0 12px 0 !important;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      padding-bottom: 0 !important;
    }
  }
}
</style>
